@font-face {
  font-family: "skywalk";
  src: local("skywalks"), url(./asstes/fonts/skywalks.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "sylfaen";
  src: local("sylfaen"), url(./asstes/fonts/sylfaen.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "arial-narrow";
  src: local("arial"), url(./asstes/fonts/arial.ttf) format("truetype");
  font-display: swap;
}

html {
  overscroll-behavior-x: contain;
}

body {
  overscroll-behavior-y: contain;
  overscroll-behavior-x: contain;
  /* -ms-touch-action: none; */
  margin: 0;
}

.app {
  width: 534px;
  height: 808px;
  margin: 0 auto;
}

h1 {
  margin: 0;
}

.home-view {
  /* height: 100vh;
  width: 100vw; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("./asstes/icons/backgroung.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.titles {
  height: 70%;
  /* padding-bottom: 80px; */
  /* border-left: 3.5px solid #ffffff; */
  margin-left: 60px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  padding-right: 30px;
}

.main-title {
  color: #ffffff;
  font-family: "skywalk", "sylfaen";
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
}


.title {
  color: #ffffff;
  font-family: "skywalk", "sylfaen";
  font-weight: 500;
  font-size: 80px;
  line-height: 120px;
}

.playback-view {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./asstes/icons/dots.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.playback-title-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  width: 80%;
}

.title-playback {
  color: #ffffff;
  font-family: "skywalk", "sylfaen";
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  line-height: 120px;
  line-height: 50px;
}

.description {
  color: #ffffff;
  font-family: "sylfaen";
  font-weight: 500;
  font-size: 18px;
  align-self: flex-end;
  /* margin-top: -20px; */
  margin-top: 2px;
}

.soundicon {
  width: 60px;
}

.timeline-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.timeline {
  width: 60%;
}

.timeline::-webkit-slider-runnable-track {
  height: 5px;
  background: #ffffff;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  margin-top: -5px;
}

input[type="range"]::-moz-range-thumb {
  background-color: #ffffff;
  width: 10px;
  height: 10px;
}

.nav-back {
  width: 60px;
  position: absolute;
  bottom: 20px;
  left: 10px;
  align-self: start;
}

.selector-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* row-gap: 20px; */
  /* padding: 0 80px; */
  background-image: url("./asstes/icons/dots.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.selector-title {
  font-size: 20px;
  color: #ffffff;
  font-family: "skywalk", "sylfaen";
  font-weight: 500;
}

.lower-circles {
  width: 100px;
}

/* .playback-lower-circles {
  width: 100px;
  position: absolute;
  bottom: 30px;
  right: 60px;
}

.playback-upper-circles {
  width: 170px;
  position: absolute;
  top: -10px;
  left: -10px;
} */

.selector-maintitle-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 80%;
}

.selector-maintitle-holder .title {
  /* font-size: 130px; */
  font-size: 60px;
  line-height: 60px;
}

.selector-titles-holder {
  padding: 0 80px;
}

.selector-titles-holder ul li {
  list-style-type: disc;
  margin-bottom: 20px;
}

.selector-titles-holder ul li::marker {
  color: #ffffff;
  font-size: 1.8em;
}

.selector-titles-holder ul li h1{
  font-family: "arial-narrow";
}
